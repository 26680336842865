
<template>
    <el-dialog :visible.sync="depconfirms" title="编辑商户" :close-on-click-modal='false' width="50%" @close='bindclose'>
    <div>
        <q-form ref="myForm" greedy class="q-gutter-md add">
        <div class='maxgao'>
            <q-input 
                style='margin-top: 0;' 
                class="add" 
                :dense='true' 
                outlined  
                v-model="bindDatas.mntName" 
                label="商户名称" 
                lazy-rules 
                autogrow
                :rules="[required.mntNameRule,required.mntNameLengthRule]"
            />
            <q-input 
                style='margin-top: 0;' 
                class="add" 
                :dense='true' 
                outlined  
                autogrow
                type="textarea"
                v-model="bindDatas.mntId" 
                label="商户号*" 
                lazy-rules 
                :rules="[required.mntIdRule,required.mntIdLengthRule]"
            />
            <!-- 商户秘钥 -->
            <q-input
                class="add"
                :type="isPwd ? 'password' : 'text'"
                ref="input" 
                :dense='true' 
                outlined
                v-model="bindDatas.mntKey" 
                label="商户秘钥*"
                lazy-rules 
                :rules="[required.mntKeyRule,required.mntKeyLengthRule]"
            >
                <template v-slot:append>
                    <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                    />
                </template>
            </q-input>
            <!-- 绑定科室区域 -->
            <!-- <comboAmtTabel
            ref="comboAmtTabel"
            :addqueryData.sync='addqueryDatas'
            @addDeparts='addDeparts'
            @delhosp='delhosp'
            @edit='editzhidingHosp'
            @qingchu='qingchuzdHosp'
            @search='getcombohosp'
            /> -->
            <q-card flat bordered class="my-card">
                <q-card-section>
                    <div>
                        <!-- 搜索指定医院区域 -->
                        <div class="adddepart">
                            <!-- <q-input outlined :dense="true" v-model="addqueryDatas.chaxun" label="请输入医院名称搜索">
                            <template v-slot:append>
                                <q-icon name="close" v-if="addqueryDatas.chaxun" @click.stop="qingchu" class="cursor-pointer" style="font-size: 16px;"/>
                                <q-icon name="search" @click='search'/>
                            </template>
                            </q-input> -->

                            <q-btn color="blue-6" label="绑定科室" @click='bindDeparts'/>
                        </div>
                        <!-- 指定医院列表区域 -->
                        <div>
                            <el-table
                            :data="bindqueryDatas.alldep"
                            style="width: 100%"
                            max-height="250">
                            <el-table-column
                            fixed
                            prop="cityName"
                            label="城市">
                            </el-table-column>
                            <el-table-column
                            fixed
                            show-overflow-tooltip
                            prop="hospName"
                            label="医院">
                            </el-table-column>
                            <el-table-column
                            fixed
                            show-overflow-tooltip
                            prop="departName"
                            label="科室">
                            </el-table-column>
                            <!-- <el-table-column
                            fixed
                            prop="datedesc"
                            label="科室描述">
                            </el-table-column> -->
                            <el-table-column
                            align='center'
                            label="操作"
                            width="120">
                            <template slot-scope="anniu">
                                <el-button
                                type="text"
                                size="small"
                                @click='deldepart(anniu.row)'
                                >
                                解绑
                                </el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
            <!-- 自定义金额区域结束 -->
          

          
        </div>
          

        <div class="addbtn">
          <q-btn
              class="kong"
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          />
          <q-btn
              label="保存"
              color="blue-6"
              @click="binddepok"
          />
        </div>
      </q-form>
    </div>

    <!-- 绑定科室弹窗 -->
    <depbindbox
        ref="depbindbox"
        :addqueryData.sync='bindqueryDatas'
        :departAdd.sync='departAdd'
        @closeadddepart='closeBindDep'
        @okadd='okbind'
    />
    <!-- 绑定科室弹窗结束 -->
    </el-dialog>
    
</template>
<script>
import  {required}  from '@/api/components/required.js' // 表单验证规则
import depbindbox from '@/components/Mnt/components/binddep/index' // 绑定科室
import { provinceList,cityList,hospList } from '@/api/City.js'; // 获取城市接口
import { MntSave,MntBindList,MntUnbindDep,MntBindDep } from '@/api/Mnt.js'; // 用户订单增删改查接口
import { notifys } from '@/api/components/Notify.js'


export default {
    props: {
        bindqueryData:{
            type: Object,
            required: true,
        },
        bindData:{
            type: Object,
            required: true,
        },
        depconfirm:{
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            required,
            
            isPwd: true,

            departAdd: false, //控制绑定科室弹窗
        }
    },
    created(){
        this.getprovince()

    },
    components:{
        depbindbox, //绑定科室弹窗
    },
    computed:{
        bindqueryDatas:{
            get(){
                return this.bindqueryData
            },
            set(v){
                this.$emit('update:bindqueryData', v)
            }
        },
        depconfirms:{
            get(){
                return this.depconfirm
            },
            set(v){
                this.$emit('update:depconfirm',v)
            }
        },
        bindDatas:{
            get(){
                return this.bindData
            },
            set(v){
                this.$emit('update:bindData',v)
            }
        }
    },
    methods:{
            // 获取省份信息
            async getprovince(){
                const { data:res } = await provinceList()
                console.log(res);
                this.bindqueryDatas.province = res.data.provinces
            },
            // 获取指定医院数据
            async getbinddep(){
                const { data: res } = await MntBindList({id: this.bindData.id})
                this.bindqueryDatas.alldep= res.data.departmentVos
            },
            // 绑定科室
            bindDeparts(){
                this.departAdd = true;  
                console.log('绑定科室');
            },
            // 移除绑定科室
            async deldepart(row){
                console.log(row);
                this.$q.dialog({
                    title: '解绑',
                    message: '您是否要将此科室与该用户解绑',
                    ok: { label: '确定'},
                    cancel:{ label: '取消',flat: true}
                }).onOk( async() => {
                    // console.log('OK')
                    const { data: res } = await MntUnbindDep({
                        id: row.id,
                    })
                    if(res.code===20000){
                        this.getbinddep() // 获取科室数据
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                })
                
                console.log('移除绑定科室');
            },
            //绑定医院弹窗关闭时
            bindclose(){
                this.isPwd = true
                this.depconfirms = false
            },
            // 点击保存时
            async binddepok(){
                const { data: res } = await MntSave({
                    id: this.bindDatas.id,
                    mntId: this.bindDatas.mntId,
                    mntName: this.bindDatas.mntName,
                    mntKey: this.bindDatas.mntKey,
                })
                if(res.code ===20000){
                    this.bindclose()
                    this.$emit('successEdit')
                    notifys('positive',res.message)
                }else{
                    notifys('negative',res.message)
                }
            },
            // 点击取消时
            quxiao(){
                this.depconfirms = false
            },

            // 绑定科室确定时
            okbind(){
                const alldepid = []
                this.bindqueryDatas.depid.map(item=>{
                    alldepid.push(item.id)
                })
                this.$refs.depbindbox.$refs.appointdepart.validate().then(async outcome =>{
                    if(outcome){
                        const { data: res } = await MntBindDep({
                            merchantId: this.bindDatas.id,
                            cityId:this.bindqueryDatas.cityId.id,
                            hospId:this.bindqueryDatas.hospid.id,
                            departIds: alldepid.join(',')
                        })
                        if(res.code===20000){
                            this.closeBindDep() // 关闭绑定科室弹窗
                            this.getbinddep() // 获取科室数据
                            notifys('positive',res.message)
                        }else{
                            notifys('negative',res.message)
                        }
                    }
                })
                console.log(alldepid); // 获取所有科室的id
            },
            // 指定科室关闭时
            closeBindDep(){
                this.bindqueryDatas.city = []
                this.bindqueryDatas.hosp = []
                this.bindqueryDatas.dep = []
                
                this.bindqueryDatas.hospid = ''
                this.bindqueryDatas.cityId = ''
                this.bindqueryDatas.provinceid = ''
                this.bindqueryDatas.depid = []
                this.departAdd = false
            }
    },
}
</script>
<style lang="less" scoped>
.shieldhosp{
  margin-top: .625rem;
}
.adddepart{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0;
}
.departtop{
  margin: .625rem 0;
}
.kong{
  margin: 0 .625rem !important;
}
.operation{
    width: 100%;
    margin-top: 0;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}

</style>
