import { createAPI } from "../utils/request"

export const MntAllData = data => createAPI(`/base/mnt/get/${data.id}`, 'get', data) //商户详细信息

export const MntChangeStatus = data => createAPI(`/base/mnt/status/${data.id}`, 'get', data) //商户启用禁用

export const MntDefault = data => createAPI(`/base/mnt/default/${data.id}`, 'get', data) //设置默认商户

export const MntDelete = data => createAPI(`/base/mnt/delete/${data.id}`, 'get', data) //商户删除

export const MntBindList = data => createAPI(`/base/mnt/bind/list/${data.id}`, 'get', data) //查詢商戶指定的科室

export const MntBindDep = data => createAPI(`/base/mnt/bind/${data.merchantId}/${data.cityId}/${data.hospId}`, 'get', data) //商户指定科室

export const MntUnbindDep = data => createAPI(`/base/mnt/unbind/${data.id}`, 'get', data) //商户删除指定科室

export const MntList = data => createAPI('/base/mnt/list', 'get', data) //商户查询

export const MntAdd = data => createAPI('/base/mnt/create', 'post', data) //商户新增

export const MntSave = data => createAPI('/base/mnt/save', 'post', data) //商户编辑

