<template>
  <div>
    <q-form ref="myForm" greedy class="q-gutter-md add">
          <q-input 
            class="add" 
            :dense='true' 
            outlined  
            v-model="getadddata.mntName" 
            label="商户名称*" 
            lazy-rules 
            autogrow
            :rules="[required.mntNameRule,required.mntNameLengthRule]"
          />
          <q-input 
            class="add" 
            :dense='true' 
            outlined  
            v-model="getadddata.mntId" 
            label="商户号*" 
            lazy-rules 
            autogrow
            :rules="[required.mntIdRule,required.mntIdLengthRule]"
          />
          <!-- 密码框 -->
          <q-input
              class="add"
              :type="isPwd ? 'password' : 'text'"
              ref="input" 
              :dense='true' 
              outlined
              v-model="getadddata.mntKey" 
              label="商户秘钥*"
              lazy-rules 
              :rules="[required.mntKeyRule,required.mntKeyLengthRule]"
          >
              <template v-slot:append>
                  <q-icon
                      :name="isPwd ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd = !isPwd"
                  />
              </template>
          </q-input>
      <div class="addbtn">
        <el-popover
          placement="bottom"
          width="155"
          v-model="visible">
          <p>未保存的数据将被丢弃, 是否继续?</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
          </div>
          <el-button slot="reference">取消</el-button>
        </el-popover>
          <!-- <q-btn
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          /> -->
          <el-button class="xz" slot="reference" type="primary" @click="addorder" >添加</el-button>
          <!-- <el-button slot="reference" type="primary" @click="addcommitsorder" >保存并提交</el-button> -->
          <!-- <q-btn
              label="添加"
              color="blue-6"
              @click="addorder"
          /> -->
      </div>
    </q-form>
    
  </div>
</template>

<script>
import { mapState } from 'vuex' //vuex获取数据
import { MntAdd } from '@/api/Mnt.js'; // 用户订单增删改查接口

import axios from 'axios'
import { makeDebounce } from "@/utils/debounce.js";  

import selectq from '@/components/Order/select' // 普通下拉框组件
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
  props:{
    addMntata: { // 新增订单信息
      type: Object,
      required: true
    }
  },
  created(){
  },
  data() {
    return {
      visible: false, //控制取消是否关闭弹窗
      loadingState: false, //控制查询版本号加载是否出现 控制查询按钮是否出现

      required, //表单验证规则

      isPwd: true, // 控制秘钥是否显示

      // orderTypemodel: '', // 订单类型选择
      // ispaymodel: '', //代理商是否付款选择,
      checkReportFilesList: [], //检查报告储存信息
      dcmReportFilesList: [], // 影像资料储存信息
      addmassage: '',
      alert: false, // 控制上传文件提示

      checkprogress: 0, //进度条
      checkprogressLabel: '', //进度百分比
      fileName: '', // 文件名

    //   addcommit: false, // 控制是否触发提交
    };
  },
  components:{
    selectq, // 普通下拉组件
  },
  computed:{
    ...mapState([
      'apkType',//应用名称
      'publishStatus',//发布状态
      'publishType',//发布类型
      'osType',//系统类型
      'Permissionslist' // 权限词典
    ]),
    getadddata:{
      get(){
          return this.addMntata;
      },
      set(v){
          this.$emit('update:addMntata',v)
      }
    }
  },
  watch:{
  },
  methods: {
    //点击取消时
    quxiao(){
      this.$refs.myForm.resetValidation()
      this.visible = false // 控制取消时小弹窗
      this.$emit('closedialog')
      console.log('点击了取消');
    },
    /**
     * 提交订单时
     */
    addorder:makeDebounce(function(){ 
        this.$refs.myForm.validate().then( async outcome =>{
        if (outcome){
          try {
            const { data: res } = await MntAdd({ 
              mntId: this.getadddata.mntId,
              mntName: this.getadddata.mntName,
              mntKey: this.getadddata.mntKey,
            })
            // const a = false
            if(res.code===20000){
              notifys('positive',res.message)
              this.$emit('closedialog')
              this.$emit('AddMntSuccess')
            }else{
              notifys('negative',res.message)
            }
          }catch (e) {
            console.log(e);
          }
        }
      })
    },500)
  },
};
// const { data: res } = await axios({
//   method: 'post',
//   url: 'order/userOrder/information/save/',
//   data: filedata,
//   timeout:9999999999999999999, 
//   headers:{
//       token: sessionStorage.getItem("token")
//   }
// })
</script>

<style lang='less' scoped>
.my-card{
  width: 100%;
  margin-bottom: .5rem;
}
.add {
  width: 100%;
  margin-left: 0;
  margin-top: 0.1875rem;
}
.addbtn {
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
}
.xz{
  margin-left: .625rem;
}
.wenjian{
  font-size: 17px;
  font-weight: bold;
}
</style>
