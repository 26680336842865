<template>
  <div id="Mnt" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="基础数据管理" icon="iconfont icon-ziyuan143" />
      <q-breadcrumbs-el label="商户管理" icon="iconfont icon-shanghu" to="/mnt/list" />
    </q-breadcrumbs>
    <div class="Mntbox" id="Mntbox">
      <!-- 搜索组件 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="row" id="searchbox">
            <div class="col-9 search">
              <q-input class='operation' :dense='true' outlined  v-model="queryData.mntName" label="商户名称">
                <template v-if="queryData.mntName" v-slot:append>
                  <q-icon name="close" @click.stop="queryData.mntName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                </template>
              </q-input>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="mnt.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='search'
              >
              搜索
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="mnt.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='chongzhi'
              >
              重置
              </el-button>
            </div>
            <div class="col  addbtn">
              <!-- :disabled='multipleSelection.length==0? true : false' -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="mnt.add").length>0?false:true'
                type="primary"
                size='medium'
                @click='addApp'
              >
              新增
              </el-button>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <!-- 表格区 -->
      <q-card class="my-card">
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          ref="multipleTable"
          :data="MntList"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"  
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column show-overflow-tooltip align='center' prop="mntName" label="商户名称" min-width='93px'></el-table-column>
          <el-table-column show-overflow-tooltip align='center' prop="mntId" label="商户号" min-width='104px'></el-table-column>
          <el-table-column  align='center' prop="createTime" label="创建时间" min-width='151px'></el-table-column>
          <!-- 系统类型 -->
          <el-table-column align='center' prop="status" label="是否启用" min-width="143px" >
            
            <template slot-scope="scope">
                <el-switch
                  :disabled='scope.row.isDefault? true : false'
                  @change='changeStatus(scope.row)'
                  v-model="scope.row.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
                <!-- {{ scope.row.status ? '启用' : '关闭' }} -->
            </template>
          </el-table-column>
          <!-- 系统类型 -->
          <el-table-column align='center' prop="isDefault" label="默认商户" min-width="90px" >
            <template slot-scope="scope">
              <el-switch
                  :disabled='scope.row.status? false : true'
                  @change='mntDefault(scope.row)'
                  v-model="scope.row.isDefault"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              <!-- {{ scope.row.isDefault ? '默认商户' : '非默认商户' }} -->
            </template>
          </el-table-column>
          <el-table-column align='center' label="操作" width="250" fixed="right">
            <template slot-scope="scope">
              <!-- 领取 -->
              <q-btn
                :disable='Permissionslist.filter(item=>item==="mnt.edit").length>0? false : true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-Edit" 
                @click='editMnt(scope.row)'>
                <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                  编辑
                </q-tooltip>  
              </q-btn> 
              <!-- 审核@click='closeApp' -->
              <!-- <q-btn
                :disable='Permissionslist.filter(item=>item==="mnt.edit").length>0? false : true'
                class="czbtn"  
                size="sm" 
                color="yellow-8" 
                icon="iconfont icon-sheweimoren" 
                @click='mntDefault(scope.row)'
              >
                <q-tooltip content-class="bg-yellow-8" :offset="[10, 10]">
                  默认商户
                </q-tooltip>  
              </q-btn> -->
              <!-- :disable='Permissionslist.filter(item=>item==="apk.delete").length>0 && scope.row.publishStatus==0? false : true' -->
              <q-btn
                :disable='Permissionslist.filter(item=>item==="mnt.delete").length>0 && !scope.row.isDefault ? false : true'
                class="czbtn"  
                size="sm" 
                color="red-7" 
                icon="iconfont icon-ashbin" 
                @click='delMnt(scope.row)'
              >
                <q-tooltip content-class="bg-red-7" :offset="[10, 10]">
                  删除
                </q-tooltip>  
              </q-btn>
            </template>
          </el-table-column>
          
        </el-table>
         <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getMntList()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>

      <!-- app新增发布弹窗 -->
      <el-dialog :visible.sync="addMntConfirm" width="35%" title="新增商户" :close-on-click-modal='false' @close="closerelease">
        <addMnt
          class="el-dialog__body"
          ref='addMnt'
          :addMntata.sync='addMntata'
          @closedialog='closerelease'
          @AddMntSuccess='getMntList()'
        >
        </addMnt>
      </el-dialog> 

      <!-- 绑定科室 -->
      <editMnt
        ref="userdepart"
        :depconfirm.sync='editconfirm'
        :bindqueryData.sync='bindqueryData'
        :bindData='bindData'
        @successEdit='getMntList()'
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import selectq from '@/components/Order/select/index2.vue' // 下拉框组件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import addMnt from '@/components/Mnt/addMnt' // 新增商户弹窗
import editMnt from '@/components/Mnt/editMnt' // 编辑商户㢟

import { makeDebounce } from "@/utils/debounce.js";  
import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions,optionget } from '@/api/components/checkcitys.js'; // 获取城市接口

import { publishList,publishDown,publishApp,publishDelete } from '@/api/Publish.js'; // 用户订单增删改查接口
import { MntList,MntChangeStatus,MntDelete,MntDefault,MntBindList,MntAllData } from '@/api/Mnt.js'; // 用户订单增删改查接口
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getMntList()
    document.onkeydown=e=>{
      let _key = window.event.keyCode;
      if(_key===13){
        this.search()
      }  
    }
  },
  data() {
    return {
        tableHeight:0, // 列表最大高度

        ordervisible: false, //控制表格loading
        nodatetype:'', //无内容时显示
        // 分页设置
        queryData:{ // element分页信息
            page: 1,
            pageSize: 10,
            // orderId: '', // 订单号
            mntName:''
        },
        total:0, //总条数

        MntList:[], //资料审核列表数据

        addMntConfirm: false, //控制商户新增
        addMntata:{ //新增商户所需信息
          mntId:'', //商户号
          mntName:'', //商户名称
          mntKey:'', //秘钥
        },

        editconfirm: false, // 控制编辑商户
        bindData:{}, //绑定科室所需信息
        bindqueryData:{
          alldep:[], //储存科室列表数据

          hospid:'', //医院 .id
          cityId:'', //城市 .id
          provinceid:'', //省份 .id
          depid:[], // 科室 id 储存数组
          
          province:[], // 指定医院时 省份数据
          city:[], // 指定医院时 城市数据
          hosp: [], // 指定医院时医院数据
          dep:[], // 所有科室数据
        },

    };
  },
  components:{
    elementPagination, //分页组件
    selectq,
    addMnt, //新增商户弹窗
    editMnt, //编辑商户弹窗
  },
  computed:{
  ...mapState([
      'apkType',//应用名称
      'publishStatus',//发布状态
      'publishType',//发布类型
      'osType',//系统类型
      'Permissionslist' // 权限词典
    ]),
  },
  watch:{
  },
  methods: {
    // setTimeout(async() =>{},500)
    /**
     * 获取商户列表
     */
    getMntList(){ 
      this.nodatetype=''
      this.ordervisible = true
      setTimeout(async() =>{
        const { data: res } = await MntList({
          ...this.queryData,
        })
        console.log(res);
        if(res.code===20000){
          this.MntList = res.data.merchants
          this.total = res.data.totals * 1
        }

        let height = document.getElementById('searchbox').offsetHeight + 85
        this.tableHeight = getHeight('Mntbox',height)

        this.ordervisible = false
        this.nodatetype='暂无数据'
      },500)
      
    },
    /**
     * 改变商户启用状态
     */
    async changeStatus(row){
      const { data: res } = await MntChangeStatus({id: row.id})
      if(res.code===20000){
        notifys('positive',res.message)
      }else{
        notifys('negative',res.message)
      }
    },
    // 新增app
    addApp(){ 
      this.addMntConfirm = true
    },
    // 新增弹窗关闭时
    closerelease(){
      this.addMntata.mntId=''  //商户号清空
      this.addMntata.mntName=''  //商户名称清空
      this.addMntata.mntKey=''  //商户秘钥必填
      this.$refs.addMnt.$refs.myForm.resetValidation()
      this.addMntConfirm = false
    },
    /**
     * 点击删除
     */
    delMnt(row){
      this.$q.dialog({
          title: '删除',
          message: `你确定要删除此商户吗?`,
          ok: { label: '确定'},
          cancel: { label: '取消',flat: true},
      }).onOk(async() => {
          try{
              const { data: res } = await MntDelete({ id: row.id})
              console.log(res);
              if(res.code===20000){
                this.getMntList()
                notifys('positive',res.message)
              }else{
                notifys('negative',res.message)
              }
          }catch (e){
              console.log(e);
          }
      })
      console.log('点击了删除');
    },
    /**
     * 点击默认商户
     */
    async mntDefault(row){
      // this.$q.dialog({
      //     title: '默认商户',
      //     message: `你确定要将此商户设置为默认商户吗?`,
      //     ok: { label: '确定'},
      //     cancel: { label: '取消',flat: true},
      // }).onOk(async() => {
          try{
              const { data: res } = await MntDefault({ id: row.id})
              console.log(res);
              if(res.code===20000){
                this.getMntList()
                notifys('positive',res.message)
              }else{
                notifys('negative',res.message)
              }
          }catch (e){
              console.log(e);
          }
      // })
    },
    /**
     * 点击编辑
     */
    async editMnt(row){
      console.log(row);
      const {data: res} = await MntAllData({id:row.id})
      if(res.code===20000){
        const { data: depdata } = await MntBindList({id: row.id})
        if(depdata.code==20000){
          this.bindqueryData.alldep = depdata.data.departmentVos
          console.log(this.bindqueryData.alldep);
          this.bindData = res.data.merchant
          this.editconfirm = true
        }
      }
    },

    // 点击重置
    chongzhi:makeDebounce(function(){
      this.queryData.page = 1
      this.queryData.mntName = ''
      this.getMntList()
    },200),
    //点击搜索
    search:makeDebounce(function(){
      this.queryData.page = 1
      this.getMntList()
    },200),
    // 分页被修改时
    changepageSize(val){
      this.queryData.pageSize = val
      this.getMntList()
    },
    changePage(val){
      this.queryData.page = val
      this.getMntList()
    }
    
  },
};
</script>

<style lang='less' scoped>
#Mnt{
  height: 100%;
}
.Mntbox{
  height: 90%;
}
.search{
  display: flex;
  flex-direction: row ;
  align-items: flex-end;
  flex-wrap: wrap;
  .operation{
    margin: .625rem .625rem 0 .625rem;
    width: 15.625rem;
  }
}
.tabletop{
  display: flex;
  flex-direction:row-reverse;
  padding: .3125rem 1rem;
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}
.resetquery{
  margin: .625rem .625rem 0 .625rem;
}
.czbtn{
  margin-right: .75rem;
}
.Paginationstyle{
  margin-top: .9375rem;
}
.upload{
  display: none;
}
/deep/.el-dialog__body{
  padding: 10px 10px !important;
}
</style>
